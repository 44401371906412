import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const PhotoUploadPage = ({ token }) => {
  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null); // For optional back image
  const [frontPreview, setFrontPreview] = useState(null);
  const [backPreview, setBackPreview] = useState(null); // Preview for back image
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [visitingCard, setVisitingCard] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to resize the image
  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      const canvas = document.createElement("canvas");
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;
        img.onload = () => {
          let width = img.width;
          let height = img.height;

          if (width > maxWidth || height > maxHeight) {
            if (width > height) {
              height *= maxWidth / width;
              width = maxWidth;
            } else {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(
                new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                })
              );
            },
            file.type,
            1
          );
        };
      };

      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleFrontFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        const resizedFile = await resizeImage(selectedFile, 1280, 720);
        setFrontFile(resizedFile);
        const previewUrl = URL.createObjectURL(resizedFile);
        setFrontPreview(previewUrl);
      } catch (error) {
        console.error("Error resizing image:", error);
      }
    }
  };

  const handleBackFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        const resizedFile = await resizeImage(selectedFile, 1280, 720);
        setBackFile(resizedFile);
        const previewUrl = URL.createObjectURL(resizedFile);
        setBackPreview(previewUrl);
      } catch (error) {
        console.error("Error resizing image:", error);
      }
    }
  };

  // Handle photo upload (front required, back optional)
  const handlePhotoUpload = async () => {
    if (!frontFile) {
      alert("Please select the front image first.");
      return;
    }

    const formData = new FormData();
    formData.append("front", frontFile);
    if (backFile) {
      formData.append("back", backFile); // Append back image if provided
    }

    try {
      setLoading(true);
      const res = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/api/card/upload",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const data = await res.json();

      if (res.ok) {
        alert("Photo uploaded successfully");
        setVisitingCard(data.visitingCard);
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error("Error during photo upload:", error);
      alert("Photo upload failed, please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle visiting card update
  const handleUpdate = async () => {
    if (!visitingCard) return;

    try {
      setUpdating(true);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/card/update/${visitingCard.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(visitingCard),
        }
      );

      const data = await res.json();

      if (res.ok) {
        alert("Visiting card updated successfully");
        resetVisitingCard();
        resetImages();
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error("Error during visiting card update:", error);
      alert("Failed to update visiting card, please try again.");
    } finally {
      setUpdating(false);
    }
  };

  // Handle visiting card field changes
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setVisitingCard((prev) => ({ ...prev, [name]: value }));
  };

  // Reset front and back images
  const resetImages = () => {
    setFrontFile(null);
    setBackFile(null);
    setFrontPreview(null);
    setBackPreview(null);
  };

  const resetVisitingCard = () => {
    setVisitingCard(null);
  };

  const handleCancel = () => {
    resetImages();
    resetVisitingCard();
  };

  return (
    <motion.div
      className="flex flex-col items-center"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <h2 className="text-xl font-semibold my-4">Upload Visiting Card</h2>

        {/* Front Image Upload */}
        {!frontPreview ? (
          <>
            <label className="mb-2">Choose Front Image (Required):</label>
            <input
              className="p-2 border border-gray-300 rounded mb-4"
              type="file"
              accept="image/*"
              capture="environment"
              onChange={handleFrontFileChange}
            />
          </>
        ) : (
          <motion.div
            className="mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <label className="mb-2">Front Image Preview:</label>
            <img
              src={frontPreview}
              alt="Front Preview"
              className="h-48 object-cover rounded shadow"
            />
          </motion.div>
        )}

        {/* Back Image Upload (Optional) */}
        {frontPreview && !backPreview && !visitingCard && (
          <>
            <label className="mb-2">
              Choose Back Image (Optional):
              <span className="block text-sm text-gray-500">
                You can skip this if not available
              </span>
            </label>
            <input
              className="p-2 border border-gray-300 rounded mb-4"
              type="file"
              accept="image/*"
              capture="environment"
              onChange={handleBackFileChange}
            />
          </>
        )}

        {/* Back Image Preview */}
        {backPreview && (
          <motion.div
            className="mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <label className="mb-2">Back Image Preview:</label>
            <img
              src={backPreview}
              alt="Back Preview"
              className="h-48 object-cover rounded shadow"
            />
          </motion.div>
        )}
      </div>

      {visitingCard && (
        <div className="flex flex-col mb-4 w-full">
          <h2 className="text-xl font-semibold mb-4">Visiting Card Details</h2>
          <label className="mb-2">Company Name:</label>
          <input
            className="p-2 border border-gray-300 rounded mb-2"
            type="text"
            name="company"
            value={visitingCard.company}
            onChange={handleFieldChange}
            placeholder="Company Name"
          />
          <label className="mb-2">First Name:</label>
          <input
            className="p-2 border border-gray-300 rounded mb-2"
            type="text"
            name="firstName"
            value={visitingCard.firstName}
            onChange={handleFieldChange}
            placeholder="First Name"
          />
          <label className="mb-2">Last Name:</label>
          <input
            className="p-2 border border-gray-300 rounded mb-2"
            type="text"
            name="lastName"
            value={visitingCard.lastName}
            onChange={handleFieldChange}
            placeholder="Last Name"
          />
          <label className="mb-2">Mobile:</label>
          <input
            className="p-2 border border-gray-300 rounded mb-2"
            type="text"
            name="mobile"
            value={visitingCard.mobile}
            onChange={handleFieldChange}
            placeholder="Mobile"
          />
          <label className="mb-2">Email:</label>
          <input
            className="p-2 border border-gray-300 rounded mb-2"
            type="text"
            name="email"
            value={visitingCard.email}
            onChange={handleFieldChange}
            placeholder="Email"
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleUpdate}
            disabled={updating}
          >
            {updating ? (
              <ClipLoader size={25} color="#ffffff" />
            ) : (
              "Update Details"
            )}
          </button>

          <button
            className="bg-red-500 text-white px-4 py-2 rounded mt-2"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      )}

      {frontPreview && !visitingCard && (
        <motion.button
          className="bg-green-500 text-white px-4 py-2 rounded"
          onClick={handlePhotoUpload}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={loading}
        >
          {loading ? <ClipLoader size={25} color="#ffffff" /> : "Upload Photo"}
        </motion.button>
      )}
    </motion.div>
  );
};

export default PhotoUploadPage;
